import { Box, Button, Divider, Typography } from "@mui/material";
import Link from "next/link";
import FourOhFourImage from "../assets/images/FourOhFourImage.svg";
import FourOhFourBackgroundImage from "../assets/images/FourOhFourBackgroundImage.svg";
import { Header } from "../layout/header";
import { ArrowForwardIos } from "@mui/icons-material";

export default function FourOhFour() {
	return (
		<>
			<Box>
				<Header />

				<Box sx={{ textAlign: "center", mt: 8, mb: 4 }}>
					<Box
						sx={{
							backgroundImage: `url(${FourOhFourBackgroundImage})`,
							width: 215.56,
							height: 220.14,
							margin: "0 auto 37.84px auto",
						}}
					>
						<img
							style={{
								width: 96,
								marginTop: "30px",
								objectFit: "contain",
							}}
							alt="Coming Soon"
							src={FourOhFourImage}
						></img>
						<Divider
							sx={{
								backgroundColor: "#263238",
							}}
						></Divider>
					</Box>

					<Typography variant="h1">Nothing here</Typography>
					<Typography variant="body2" sx={{ opacity: 0.4, mt: 2 }}>
						Looks like you stumbled on wrong link
					</Typography>
					<Button
						component={Link}
						href="/"
						variant="text"
						color="primary"
						endIcon={<ArrowForwardIos sx={{ height: "16px" }} />}
						sx={{
							mt: 4,
							textTransform: "none",
							".MuiButton-endIcon": {
								marginLeft: 0,
								transform: "scale(.8)",
							},
						}}
					>
						<Typography variant="body2" sx={{ fontWeight: 700 }}>
							Go to Home
						</Typography>
					</Button>
				</Box>
			</Box>
		</>
	);
}
